import React from 'react'
import Navbar from './Navbar'
import HeaderVideo from './HeaderVideo'
import Footer from './Footer'

import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import LoaderActionButton from './LoaderActionButton'

import SocialLinksModal from './modals/SocialLinksModal'
import PrintsListModal from './modals/PrintsListModal'

import PhotosHelper from '../helpers/PhotosHelper'
import LocalStorageHelper from '../helpers/LocalStorageHelper'
import LocalStorageContext from './contexts/LocalStorageContext'

class About extends React.Component {
  constructor(props) {
    super(props);

    this.assetsCloudfrontUrl = document.getElementById('assets_cloudfront_url').value

    this.examplePrintPreviewFilenames = (() => {
      const filenames = [
        'b4214e503646087c-example-print-1-preview',
        'd9af436c1cb642a0-example-print-1-preview',
        'd9af436c1cb642a0-example-print-3-preview',
        'a2c7d267b484277e-example-print-1-preview',
        '50e413be38fcc064-example-print-2-preview',
        '5d69b6ba0deec0ba-example-print-2-preview',
        'bba79a371230ccab-example-print-1-preview',
        'd2e7f8a0a252be44-example-print-2-preview',
        '836880c4f5de50c5-example-print-2-preview',
        '712f8498060095c8-example-print-1-preview',
        'f4ef066fe9bdf1a0-example-print-1-preview',
        'e52e7919ddb14053-example-print-1-preview'
      ]

      return filenames.map((filename) => `${filename}.jpg`)
    })()
    
    this.sectionMappings = {
      about: 'About',
      prints: 'Prints',
      ordering: 'Ordering',
      delivery: 'Delivery'
    }

    this.state = {
      photoData: {},
      currentCartPhotos: [],
      currentCartPhotoTokens: [],
      currentSection: 'about'
    }
  }

  componentDidMount() {
    PhotosHelper.loadPhotos(this)
  }

  setCurrentCartPhotosFromTokens = (currentCartPhotoTokens) => {
    PhotosHelper.setCurrentCartPhotosFromTokens(this, currentCartPhotoTokens)
  }

  render() {
    return(
      <LocalStorageContext.Provider
        value={{
          currentOrderToken: LocalStorageHelper.currentOrderToken(),
          currentCartPhotos: this.state.currentCartPhotos,
          currentCartPhotoTokens: this.state.currentCartPhotoTokens,
          setCurrentCartPhotosFromTokens: this.setCurrentCartPhotosFromTokens,
        }}
      >

        <Navbar title='keenedreams'/>

        <PrintsListModal
          currentCartPhotos={this.state.currentCartPhotos}
          currentCartPhotoTokens={this.state.currentCartPhotoTokens}
          setCurrentCartPhotosFromTokens={this.setCurrentCartPhotosFromTokens}
        />

        <div className='about'>
          <div className='about__section'>
            <HeaderVideo
              videoEmbedUrl={'https://player.vimeo.com/video/888077628'}
              title={'keenedreams'}
            />
      
            <div className='about__section-nav inline-options-selector__options'>
              {
                Object.keys(this.sectionMappings).map((section) => {
                  return(
                    <div key={`select-${section}-section`} className={`about__section-nav__link inline-options-selector__option ${this.state.currentSection === section ? 'inline-options-selector__option--selected' : ''}`} onClick={() => { this.setState({ currentSection: section })}}>
                      <span>
                        {this.sectionMappings[section]}
                      </span>
                    </div>
                  )
                })

              }
            </div>
          </div>

          { this.state.currentSection === 'about' &&
            <React.Fragment>
              <div className='about__section'>
                <p>
                  Welcome to Keenedreams, a collection of urban moments captured through my lens. <br className='mobile-only'/>I’m Caleb Keene, a world traveller and dedicated street photographer from New Zealand.
                </p>

                <p>Here you can explore my original street photography and order my work as limited edition, high quality giclée (fine art) prints.</p>

                <div className='about__section about-tab-links'>
                  <div className='launch-site'>
                    <LoaderActionButton
                      text={'keenedreams photos'}
                      href={'/photos'}
                      faIconClass={'fa-arrow-right'}
                    />
                  </div>
                  <div className='send-email'>
                    <a href='mailto:caleb@keenedreams.io' className='button button--action'>
                      <span>caleb@keenedreams.io</span>
                      <i className="fa-regular fa-envelope"></i>
                    </a>
                  </div>
                </div>

                <h6>How do I make my photos?</h6>
                <p>Every photo on keenedreams was taken by me, using either my Fujifilm X-T5 or X-T30 camera and a variety of lenses (I shoot mostly at telephoto focal lengths between 70mm and 250mm).</p>
                <p>The images you will find here are an ever-evolving collection of some of my favourites from my past few years of travelling the world with my camera.</p>
                
                <h6>Are the photos edited?</h6>
                <p>To my photos I apply only basic adjustments in Adobe lightroom - adjustments such as contrast, brightness, exposure, and a little saturation (to help bring out the colours that I love) as well as sometimes selective cropping of the raw shot.</p>

                <h6>My position on AI art</h6>
                <p>When it comes to any kind of art, I personally ascribe a lot of value to anything created painstakingly, slowly, inefficiently - with soul and a certain expectation of failure.</p>
                <p>For this reason I do not use any kind of AI in my own work, nor do I condone or support the use of any generative AI in the production of art for sale.</p>
              </div>

              <div className='about__section about__section--separator margin-top'>
                <p>If you have any questions please get in touch via email, or you can contact me on Instagram 😊</p>
              </div>

            </React.Fragment>
          }

          { this.state.currentSection === 'prints' &&
            <div className='about__section'>
              <p>Prints are made using a professional large-format 12-colour system with ultra-wide gamut inks on 308 gsm Hahnemühle cotton Photorag.</p>
              <p>This high-quality art paper is both acid and lignin-free and has a minimally textured and lightly defined felt structure giving a smooth matt finish.</p>

              <div className='owl-carousel-wrapper'>
                <OwlCarousel items={1} className='owl-theme' loop margin={10} nav={false} autoplay={true} autoplayTimeout={4000} dots={false}>
                  { this.examplePrintPreviewFilenames.map((filename) => {
                    return(
                      <div className='item'>
                        <img key={filename} src={`${this.assetsCloudfrontUrl}/${filename}`} />
                      </div>
                    )
                  })}
                </OwlCarousel>
                
                <div className='example-prints-link'>
                  <LoaderActionButton
                    text={'See More'}
                    href={'/example_prints'}
                    faIconClass={'fa-arrow-right'}
                  />
                </div>
              </div>

              <p className='image-key-text'>Photos of some finished prints</p>

              <p>The inks used are archival quality base pigments made from finely ground powders suspended in liquid. These particles are not water soluble and bind directly with the cotton fibres in the paper, giving excellent colour stability and fade-resistance. The prints will hold their colours and tones accurately for at least 200 years in a photo album, 80 years on display behind glass (light fastness), and 50 years without glass (gas fastness).</p>

              <h6>Limited Editions</h6>
              <p>Each print is part of a limited run of a fixed number of editions, and comes with a unique certificate of authenticity. Once each size sells out, that's it - they'll never be available again!</p>
              <p>Each certificate contains the name I've given to the piece, the edition number, production details and date, my personal signature and a unique holographic stamp that corresponds to the edition.</p>

              <h6>Sizes, Pricing, Framing</h6>
              
              <p>3 sizes are available:</p>

              <p>
                <strong>Small:</strong>&nbsp;<span className='version-text'>7″ x 5.7″ (178mm x 142mm) ~ &nbsp;€100</span>
              </p>
              <p>
                <strong>Medium:</strong>&nbsp;<span className='version-text'>10″ x 8″ (254mm x 203mm) ~ &nbsp;€140</span>
              </p>

              <p>
                <strong>Large:</strong>&nbsp;<span className='version-text'>13.8″ x 11″ (348mm x 279mm) ~ &nbsp;€170</span>
              </p>

              <p>
                <strong>Extra Large:</strong>&nbsp;<span className='version-text'>16″ x 20″ (406mm x 508mm) ~ &nbsp;€190</span>
              </p>

              <p>These sizes fit within standard frames of 8″ x 10″, 11″ x 14″, or 16″ x 20″, respectively (with internal mat for the edges)</p>
              <p>Sizes medium, large and extra large also fit perfectly within standard frames (8″ x 10″ / 11″ x 14″ / 16″ x 20″) with no mat.</p>

              <h6>Sustainability and Carbon neutral printing</h6>
              <p>
                My fine-art printing partner is 100% end-to-end carbon neutral, from production to shipping, and is partnered with the
                &nbsp;
                <a href='https://www.worldlandtrust.org/'>World Land Trust</a>
                &nbsp;
                to buy and protect land in natural habitats as a core part of their business.
              </p>

              <p>
                Print packaging is 100% recycled, and Hahnemühle paper is accredited with both
                &nbsp;
                <a href='https://www.hahnemuehle.com/fileadmin/user_upload/bilder/pdf/Zertifikate_Vegan_Alterungsbest%C3%A4ndigkeit/Zertifikat_ZA_ISO_14001_2015_englisch.PDF' target='_blank'>
                  ISO 14001
                </a>
                &nbsp;
                and
                &nbsp;
                <a href='https://www.hahnemuehle.com/fileadmin/user_upload/bilder/pdf/Zertifikate_Vegan_Alterungsbest%C3%A4ndigkeit/Ecovadis_Zertifikate/EcoVadis_Rating_Certificate_2023_EN.pdf' target='_blank'>
                  EcoVadis
                </a>
                &nbsp;
                sustainability certifications.
              </p>
            </div>
          }
          { this.state.currentSection === 'ordering' &&
            <div className='about__section'>
              <p>Placing an order doesn't require creating any account, simply add a photo to the cart and you can proceed to checkout.</p>
              <p>When you're done creating your order, you will receive an email to help keep track of things. Once your order is paid, it's sent straight for production. Prints are usually ready to send out within 2-5 business days.</p>

              <h6>Payment</h6>
              <p>All prices are in Euros (€), and include VAT.</p>
              <p>
                All major credit cards are accepted, as well as a variety of other payment methods including EPS, Bancontact, giropay, iDEAL, and Google Pay. Payments are handled by a secure
                &nbsp;
                <a href='https://stripe.com/en-de' target='_blank'>Stripe</a>
                &nbsp;
                integration, and no customer card details are ever stored on the site.
              </p>
              {/* <p>Bitcoin (BTC), Ethereum (ETH) and Cardano (ADA) cryptocurrencies are also accepted as payment options.</p> */}

              {/* <p>If you place an Order and then change your mind, you can cancel it from your Order page and you will receive a full refund. Cancellation can be made up until the print is sent for production.</p> */}

              <h6>Exchanges / Returns</h6>
              <p>If you receive your print and aren't happy with it for whatever reason, you can send it back and receive a full refund (you'll only need to cover the cost of return shipping)</p>
              <p>In the unlikely event that your print is damaged in transit or has some visual defect, get in touch and a replacement will be sent out.</p>
            </div>
          }

          { this.state.currentSection === 'delivery' &&
            <React.Fragment>
              <div className='about__section'>
                <p>Prints are produced and shipped by my fine-art printing partner in Düsseldorf, Germany.</p>
                <p>Each print is carefully wrapped in tengucho style art paper and flat-packed within rigid cardboard to ensure that it arrives in perfect condition.</p>
                <p>Delivery is a tracked courier and usually takes 4-10 days, depending on location.</p>

                <h6>Delivery Costs</h6>

                <p>
                  Germany:&nbsp; €10
                  <br />
                  United Kingdom:&nbsp; €15
                  <br />
                  European Union:&nbsp; €20
                  <br />
                  EEA / Switzerland:&nbsp; €30
                  <br />
                  United States:&nbsp; €30
                  <br />
                  Canada:&nbsp; €40
                  <br />
                  Rest of the world:&nbsp; €50
                </p>

                <div className='example-packaging-image'>
                  <img src={`${this.assetsCloudfrontUrl}/keenedreams-example-packaging.png`} />
                  <p className='image-key-text'>
                    Example Print Packaging
                  </p>
                </div>                

                <h6>Exchanges / Returns</h6>
                <p>If you receive your print and aren't happy with it for whatever reason, you can send it back and receive a full refund (you'll only need to cover the cost of return shipping)</p>
                <p>In the unlikely event that your print is damaged in transit or has some visual defect, get in touch and a replacement will be sent out.</p>
              </div>
            </React.Fragment>
          }
        </div>
        
        <SocialLinksModal />

        <Footer currentPage={'about'} />
      </LocalStorageContext.Provider>
    );
  }
}

export default About;
